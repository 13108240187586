<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Aldığım Eğitim Listesi</h3>
      </div>
      <div class="card-body">
        <div class="ads-tabs">
          <div class="tabs-menus">
            <!-- Tabs -->
            <ul class="nav panel-tabs">
              <li class="">
                <a href="#tab1" class="active" data-bs-toggle="tab">Tümü</a>
              </li>
              <li><a href="#tab2" data-bs-toggle="tab">Onaylı</a></li>
              <li><a href="#tab3" data-bs-toggle="tab">Ödeme Bekliyor</a></li>
              <li><a href="#tab4" data-bs-toggle="tab">Başlamamış</a></li>
              <li><a href="#tab5" data-bs-toggle="tab">Tamamlanmış</a></li>
              <li><a href="#tab6" data-bs-toggle="tab">Reddedilen</a></li>
            </ul>
          </div>
          <div class="tab-content">
            <div
              class="tab-pane active table-responsive border-top userprof-tab"
              id="tab1"
            >
              <table class="table table-bordered table-hover mb-0">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem :item="item" :cont="true" :s="i" />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab2"
            >
              <table class="table table-bordered table-hover mb-0">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem
                      :item="item"
                      :s="i"
                      :cont="item.type == 1"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab3"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem
                      :item="item"
                      :s="i"
                      :cont="item.type == 0"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab4"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem
                      :item="item"
                      :s="i"
                      :cont="item.begin_date > Date('Y-m-d')"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab5"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem
                      :item="item"
                      :s="i"
                      :cont="item.type == 1 && item.date_end < Date('Y-m-d')"
                    />
                  </template>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane table-responsive border-top userprof-tab"
              id="tab6"
            >
              <table class="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Kategori</th>
                    <th>Eğitimci</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in educationList">
                    <EducationItem
                      :item="item"
                      :s="i"
                      :cont="item.type == 3"
                    />
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import EducationItem from "./Components/EducationItem.vue";
export default {
  created() {
    document.title = "Aldığım Eğitim Listesi";
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    let educationInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("takeEducationList", educationInfo).then((value) => {
      this.educationList = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
  },
  data() {
    return {
      educationList: [],
      currentpage: 1,
      perpage: 8,
      totalsize: 0,
      pagesize: 0,
      educationLoad: true,
    };
  },
  methods: {},
  components: { EducationItem },
  mounted() {},
};
</script>



