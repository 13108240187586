<template>
  <tr v-if="cont">
    <td>
      <div class="media mt-0 mb-0">
        <div class="card-aside-img">
          <router-link
            :to="'/education/' + item.shortlink + '/' + item.id"
          ></router-link>
          <img
            :src="'https://storage.terapivitrini.com/' + item.thumbnail"
            alt="img"
          />
        </div>
        <div class="media-body">
          <div class="card-item-desc ms-4 p-0 mt-2">
            <router-link
              :to="'/education/' + item.shortlink + '/' + item.id"
              class="text-dark"
              ><h4 class="font-weight-semibold">
                {{ item.title }}
              </h4></router-link
            >
            <router-link :to="'/education/' + item.shortlink + '/' + item.id"
              ><i class="fa fa-clock-o me-1"></i
              >{{ item.begin_date }} </router-link
            ><br />
            <router-link :to="'/education/' + item.shortlink + '/' + item.id"
              ><i class="fa fa-clock-o me-1"></i>{{ item.end_date }}
            </router-link>
          </div>
        </div>
      </div>
    </td>
    <td>{{ item.experience_name }}</td>
    <td class="font-weight-semibold fs-16">{{ item.fullname }}</td>
    <td>
      <span class="badge badge-danger" v-if="item.type == 3">Reddedildi</span>
      <span class="badge badge-secondary" v-if="item.type == 0">Ödenmedi</span>
      <span class="badge badge-warning" v-if="item.type == 2">Onaysız</span>
      <span class="badge badge-success" v-if="item.type == 1">Onaylı</span>
      <span
        class="badge badge-info"
        v-if="item.type == 1 && item.date_end < Date('Y-m-d')"
        >Tamamlanmış</span
      >
    </td>
    <td style="width: 18%">
      <div class="">
        <router-link
          v-if="item.type == 1"
          :to="'/profile/education/participant/' + item.id + '/message'"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Eğitimciye Sor"
          data-bs-original-title="View"
          ><i class="fa fa-edit"></i
        ></router-link>
        <router-link
          v-if="item.type == 1 && item.education_type != 3"
          :to="'/profile/education/document/' + item.id + '/list'"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Eğitim Takvimi"
          data-bs-original-title="View"
          ><i class="fa fa-calendar"></i
        ></router-link>
        <router-link
          v-if="item.type == 1 && item.education_type == 3"
          :to="'/profile/education/videos/' + item.id + '/list'"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Eğitim Videoları"
          data-bs-original-title="View"
          ><i class="fa fa-film" aria-hidden="true"></i>
        </router-link>
        <router-link
          :to="'/education/' + item.shortlink + '/' + item.id"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Bilgiler"
          data-bs-original-title="View"
          ><i class="fa fa-eye"></i
        ></router-link>
        <input
          type="file"
          @change="docUploadChange(item.id)"
          v-if="item.type == 0"
          accept="application/pdf"
          style="display: none"
          :name="'file' + item.id"
          :id="'file' + item.id"
        />
        <!--<button
        v-if="item.type == 0"
        class="btn btn-primary btn-sm text-white"
        data-bs-toggle="tooltip"
        title="Dekont Yükle"
        @click="docUploadClick(item.id)"
        data-bs-original-title="View"
      >
        <i class="fa fa-file-o"></i>
      </button>-->
        <button
          v-if="item.type == 0"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Ödeme Yap"
          @click="makePay(item.id)"
          data-bs-original-title="View"
        >
          <i class="fa fa-credit-card"></i>
        </button>
        <button
          v-if="item.type == 0"
          class="btn btn-primary text-white btn-sm m-1"
          data-bs-toggle="tooltip"
          title="Eğitimi Sil"
          @click="removeItem(item.id)"
          data-bs-original-title="View"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          v-if="docUploadList[item.id] != null"
          class="btn btn-primary text-white btn-sm m-1"
          :disabled="isUpload"
          @click="docUploadFile(item.id)"
        >
          <i class="fa fa-upload" v-if="!isUpload"></i>
          <div
            v-if="isUpload"
            class="spinner-border"
            role="status"
            style="width: 15px; height: 15px"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import Swal from "sweetalert2";
export default {
  props: ["item", "cont", "s"],
  data() {
    return {
      docUploadList: [],
      isUpload: false,
    };
  },
  methods: {
    removeItem(id) {
      Swal.fire({
        title: "Eğitimi silmek istediğinize emin misin?",
        text: "Bu işlem sonrası seçilen eğtim silinecek. Devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {

          let info = { id: id };
          this.$store.dispatch("educationMemberRemove", info).then((value) => {
            if (value.message == "SCCx001") {
              this.$vToastify.success("Eğitim Başarıyla Silindi", "Başarılı!");
              this.$parent.educationList.splice(this.s, 1);
            }
            if (value.message == "ERRxENRM") {
              this.$vToastify.warning("Böyle Bir Eğitim Bulunamadı", "Uyarı!");
            }
          });
        }
      });
    },
    docUploadClick(i) {
      $("#file" + i).trigger("click");
    },
    docUploadChange(i) {
      let file = document.querySelector("input[name=file" + i + "]").files[0];
      if (file.type == "application/pdf") {
        this.docUploadList[i] = file;
        this.docUploadList.push({});
        this.docUploadList.pop();
      } else {
        this.$vToastify.warning("Sadece PDF formatında dosyalar!", "Uyarı!");
      }
    },
    makePay(i) {
      this.$router.push("/profile/education/" + i + "/pay/credit");
    },
    docUploadFile(i) {
      this.isUpload = true;
      let file = this.docUploadList[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", i);
      this.$store.dispatch("educationFileUpload", formData).then((response) => {
        this.$vToastify.success(
          "Dosya başarılı bir şekilde yüklendi",
          "Başarılı!"
        );
        this.isUpload = false;
        this.docUploadList[i] = null;
        this.item.file = response[0];
      });
    },
  },
};
</script>